<template>
  <div class="page-404 flex-col justify-align-center">
    <img class="home-bg" src="../../assets/img/h5Home_bg.png" alt="可搭吖">
    <Nav />
    <img class="img-404" src="../../assets/img/404.png" alt="可搭吖">
    <span class="txt-404">哎呀，打不开网页了呢</span>
  </div>
</template>
<script>
import Nav from './components/Nav'
export default {
  components: {
    Nav
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.page-404 {
  width: 375rem;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  .home-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .img-404 {
    width: 121rem;
    height: 114rem;
  }
  .txt-404 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14rem;
    color: #999999;
    line-height: 16rem;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-top: 15rem;
  }
}
</style>